var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "q-list",
              {
                staticClass: "rounded-borders",
                staticStyle: { "border-top": "2px solid" },
                attrs: { bordered: "" },
              },
              [
                _c(
                  "q-expansion-item",
                  {
                    attrs: {
                      "switch-toggle-side": "",
                      "expand-separator": "",
                      dense: "",
                      "header-class": "bg-white text-black",
                      icon: "perm_identity",
                      label: _vm.$label("LBL0010125"),
                    },
                  },
                  [
                    _c(
                      "q-card",
                      [
                        _c(
                          "q-card-section",
                          _vm._l(
                            _vm.targetDept.auditTeams,
                            function (user, index) {
                              return _c(
                                "q-chip",
                                {
                                  key: index,
                                  staticClass: "q-ma-none customchipdept",
                                  attrs: {
                                    outline: "",
                                    square: "",
                                    color: "primary",
                                    "text-color": "white",
                                  },
                                },
                                [
                                  _c(
                                    "q-chip",
                                    {
                                      staticStyle: {
                                        "margin-left": "-17px !important",
                                        height: "26px",
                                        "margin-right": "5px !important",
                                      },
                                      attrs: {
                                        square: "",
                                        color:
                                          user.saiInternalAuditTeamRoleCd ===
                                          "IATR000001"
                                            ? "deep-orange"
                                            : "grey",
                                        "text-color": "white",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            user.saiInternalAuditTeamRoleCd ===
                                              "IATR000001"
                                              ? _vm.$label("LBL0010126")
                                              : _vm.$label("LBL0010125")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(user.userName) +
                                      " / " +
                                      _vm._s(user.deptName) +
                                      " "
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "LBL0010127",
            columns: _vm.grid.columns,
            data: _vm.gridData,
            gridHeight: _vm.gridHeight,
            merge: _vm.grid.merge,
            editable: _vm.editable && !_vm.disabled,
            isExcelDown: false,
            filtering: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "saiInternalActionChecklistResultId",
          },
          on: { "table-data-change": _vm.tableDataChange },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable: _vm.editable && !_vm.disabled,
                            isImmShow: true,
                            requestContentsCols: _vm.requestContentsCols,
                            tableKey: "saiInternalActionChecklistResultId",
                            ibmTaskTypeCd: "ITT0000150",
                            ibmTaskUnderTypeCd: "ITTU000155",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "suffixTitle" },
            [
              _c("q-radio", {
                staticClass: "text-primary",
                attrs: {
                  size: "sm",
                  "checked-icon": "task_alt",
                  "unchecked-icon": "panorama_fish_eye",
                  dense: "",
                  "keep-color": "",
                  color: "primary",
                  val: false,
                  label: _vm.$label("LBL0010148"),
                },
                model: {
                  value: _vm.check,
                  callback: function ($$v) {
                    _vm.check = $$v
                  },
                  expression: "check",
                },
              }),
              _c("q-radio", {
                staticClass: "text-red",
                attrs: {
                  size: "sm",
                  "checked-icon": "task_alt",
                  "unchecked-icon": "panorama_fish_eye",
                  dense: "",
                  "keep-color": "",
                  color: "red",
                  val: true,
                  label: _vm.$label("LBL0010149"),
                },
                model: {
                  value: _vm.check,
                  callback: function ($$v) {
                    _vm.check = $$v
                  },
                  expression: "check",
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.deleteChecklist },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addChecklist },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.targetDept.checklist,
                          mappingType: "PUT",
                          label: "LBLSAVE",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveChecklist,
                          btnCallback: _vm.saveChecklistCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }