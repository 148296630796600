<template>
  <div>
    <div class="row">
      <div class="col-12">
        <q-list bordered class="rounded-borders" style="border-top: 2px solid;">
          <!--심사자-->
          <q-expansion-item
            switch-toggle-side
            expand-separator
            dense
            header-class="bg-white text-black"
            icon="perm_identity"
            :label="$label('LBL0010125')"
          >
            <q-card>
              <q-card-section>
                <q-chip
                  v-for="(user, index) in targetDept.auditTeams"
                  :key="index"
                  outline square 
                  color="primary"
                  text-color="white"
                  class="q-ma-none customchipdept">
                  <!--'심사팀장' : '심사자'-->
                  <q-chip square
                    :color="user.saiInternalAuditTeamRoleCd === 'IATR000001' ? 'deep-orange' : 'grey'"
                    text-color="white"
                    style="margin-left: -17px !important; height: 26px; margin-right: 5px !important;">
                    {{user.saiInternalAuditTeamRoleCd === 'IATR000001' ? $label('LBL0010126') : $label('LBL0010125')}}
                  </q-chip>
                  {{user.userName}} / {{user.deptName}}
                </q-chip>
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </q-list>
      </div>
    </div>
    <!--내부심사 체크리스트-->
    <c-table
      ref="table"
      title="LBL0010127"
      :columns="grid.columns"
      :data="gridData"
      :gridHeight="gridHeight"
      :merge="grid.merge"
      :editable="editable&&!disabled"
      :isExcelDown="false"
      :filtering="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="saiInternalActionChecklistResultId"
      @table-data-change="tableDataChange"
    >
      <template slot="suffixTitle">
        <!--전체보기-->
        <q-radio v-model="check" size="sm" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" dense keep-color color="primary" class="text-primary" :val="false" :label="$label('LBL0010148')" />
        <!--부적합/관찰사항 보기-->
        <q-radio v-model="check" size="sm" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" dense keep-color color="red" class="text-red" :val="true" :label="$label('LBL0010149')" />
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--삭제-->
          <c-btn 
            v-if="editable&&!disabled" 
            label="LBLREMOVE" icon="remove" 
            @btnClicked="deleteChecklist" />
          <!--추가-->
          <c-btn 
            v-if="editable&&!disabled" 
            label="LBLADD" icon="add" 
            @btnClicked="addChecklist" />
          <!--저장-->
          <c-btn 
            v-if="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="targetDept.checklist"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveChecklist"
            @btnCallback="saveChecklistCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable&&!disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="saiInternalActionChecklistResultId"
            ibmTaskTypeCd="ITT0000150"
            ibmTaskUnderTypeCd="ITTU000155"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'internal-action-checklist',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',
      }),
    },
    targetDept: {
      type: Object,
      default: () => ({
        saiInternalActionTargetDeptId: '',  // 내부심사 피심사팀 일련번호
        saiInternalActionId: '',  // 내부심사 수행계획서 일련번호
        deptCd: '',  // 피심사부서코드
        saiInternalActionTargetDeptStepCd: '',  // 내부심사 피심사팀 진행상태
        resultRemark: '',  // 결과요약
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklist: [], // 체크리스트 결과
        auditTeams: [], // 심사팀
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalAuditClassName' },
          { index: 1, colName: 'saiInternalAuditClassNameGubun' },
        ],
        columns: [
          {
            name: 'saiInternalAuditClassName',
            field: 'saiInternalAuditClassName',
            // ISO 구분
            label: 'ISO 구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'systemElementsName',
            field: 'systemElementsName',
            //시스템요소
            label: 'LBL0010132',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            //순서(순번)
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:30px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            //내부심사 항목
            label: 'LBL0010129',
            align: 'left',
            style: 'width:450px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            //내부심사 시<br/>착안사항
            label: 'LBL0010130',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
          {
            name: 'result',
            field: 'result',
            //내부심사결과
            label: 'LBL0010141',
            child: [
              {
                name: 'sar0000001',
                field: 'sar0000001',
                //양호
                label: 'LBL0010142',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              {
                name: 'sar0000005',
                field: 'sar0000005',
                //부적합
                label: 'LBL0010143',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              {
                name: 'sar0000010',
                field: 'sar0000010',
                //관찰사항
                label: 'LBL0010144',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
              {
                name: 'sar0000015',
                field: 'sar0000015',
                //비적용
                label: 'LBL0010145',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'check',
                checkIcon: 'task_alt',
                uncheckIcon: 'radio_button_unchecked',
                'true': 'Y',
                'false': 'N',
                color: 'green', 
              },
            ]
          },
          {
            name: 'observation',
            field: 'observation',
            //관찰내용<br/>(부적합사항<br/>및 보고서No.)
            label: 'LBL0010146',
            align: 'left',
            style: 'width:250px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'customCol',
            field: 'customCol',
            //개선번호/개선진행상태
            label: 'LBLIMPRNO_PROGRESS',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '780px',
      },
      check: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let _margin = 130;
      if (!this.disabled) {
        _margin += 40
      }
      return String(this.contentHeight - _margin) + 'px';
    },
    gridData() {
      let _gridData = this.targetDept.checklist;
      if (this.check) {
        _gridData = this.targetDept.checklist && this.targetDept.checklist.length > 0 ? 
          this.targetDept.checklist.filter(data => data.sar0000005 === 'Y' || data.sar0000010 === 'Y') : []
      }
      
      if (_gridData.length > 0) {
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _gridData[0])
        }
      return _gridData;
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['systemElementsName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.action.checklistResult.list.url;
      this.saveUrl = transactionConfig.sai.internal.action.checklistResult.save.url;
      this.deleteUrl = transactionConfig.sai.internal.action.checklistResult.delete.url;
      // code setting
      // list setting
    },
    getChecklist() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        saiInternalActionTargetDeptId: this.targetDept.saiInternalActionTargetDeptId
      }
      this.$http.request((_result) => {
        this.$set(this.targetDept, 'checklist', _result.data)
      },);
    },
    tableDataChange(props, col) {
      // let blackList = ['itemName', 'aimMatter', 'observation']
      let whiteList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
      if (whiteList.indexOf(col.name) > -1 && props.row[col.name] === 'Y') {
        this.$_.forEach(whiteList, item => {
          if (item === col.name) return
          this.$set(props.row, item, 'N')
        })
      }
    },
    /* eslint-disable no-unused-vars */
    deleteChecklist() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010007', // 삭제할 행을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  //확인
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let _deleteData = this.$_.filter(selectData, (item) => {
              return item.editFlag !== 'C'
            })

            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(_deleteData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.targetDept.checklist = this.$_.reject(this.targetDept.checklist, item);
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addChecklist() {
      this.popupOptions.title = 'LBL0010150'; // 체크리스트
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/sai/internalAudit/base/internalChecklistPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChecklistPopup;
    },
    closeChecklistPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.targetDept.checklist, { saiInternalAuditChecklistId: item.saiInternalAuditChecklistId }) === -1) {
            _data.push({
              saiInternalActionChecklistResultId: uid(),  // 내부심사 수행계획서 체크리스트 일련번호
              saiInternalActionTargetDeptId: this.targetDept.saiInternalActionTargetDeptId,  // 내부심사 피심사팀 일련번호
              saiInternalAuditChecklistId: item.saiInternalAuditChecklistId,  // 내부감사 체크리스트 항목 일련번호
              systemElementsName: item.systemElementsName,  // 시스템 요소
              itemName: item.itemName,  // 내부심사 항목
              aimMatter: item.aimMatter,  // 내부심사 시 착안 사항
              saiInternalAuditResultCd: 'SAR0000001',  // 내부심사 결과
              observation: '',  // 관찰사항
              sortOrder: item.sortOrder,  // 순번
              sar0000001: 'N',
              sar0000005: 'N',
              sar0000010: 'N',
              sar0000015: 'N',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
        this.targetDept.checklist = this.$_.concat(_data, this.targetDept.checklist)
      }
    },
    saveChecklist() {
      if (this.$comm.validTable(this.grid.columns, this.targetDept.checklist)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            let resultList = ['sar0000001', 'sar0000005', 'sar0000010', 'sar0000015']
            this.$_.forEach(this.targetDept.checklist, check => {
              this.$_.forEach(resultList, item => {
                if (check[item] === 'Y') {
                  check.saiInternalAuditResultCd = this.$_.toUpper(item);
                  return false;
                }
              })
            })
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveChecklistCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getChecklist();
      this.$emit('stepup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>